import React, { useReducer, createContext } from 'react'

const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'

const initialState = { user: null, isAuthenticated: false }
const user = JSON.parse(localStorage.getItem('user'))

// handle refresh auth case
if (user) {
	initialState.isAuthenticated = true
	initialState.user = user
}

const AuthContext = createContext({
	user: null,
	isAuthenticated: false,
	login: (userData) => {},
	logout: () => {},
})

const authReducer = (state, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				user: action.payload,
				isAuthenticated: true,
			}
		case LOGOUT:
			return {
				...state,
				user: null,
				isAuthenticated: false,
			}
		default:
			return state
	}
}

const AuthProvider = (props) => {
	const [state, dispatch] = useReducer(authReducer, initialState)

	const login = (userData) => {
		localStorage.setItem('user', JSON.stringify(userData))
		dispatch({ type: LOGIN, payload: userData })
	}
	const logout = () => {
		localStorage.removeItem('user')
		window.location.reload()
		dispatch({ type: LOGOUT })
	}

	return (
		<AuthContext.Provider
			value={{ user: state.user, isAuthenticated: state.isAuthenticated, login, logout }}
			{...props}
		/>
	)
}

export { AuthContext, AuthProvider }
