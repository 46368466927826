import React, { useContext, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Button, Form, Message } from 'semantic-ui-react'

import { AuthContext } from '../context/auth'

import Logo from '../assets/img/logo.png'

const Login = (props) => {
	const [values, setValues] = useState({})
	const [error, setError] = useState({})
	const history = useHistory()

	const context = useContext(AuthContext)

	const onChange = (event) => {
		setValues({ ...values, [event.target.name]: event.target.value })
	}

	const onSubmit = () => {
		axios({
			method: 'post',
			url: 'https://backend-dn7ew.ondigitalocean.app/login/',
			headers: { 'Content-Type': 'application/json' },
			data: values,
		})
			.then((res) => {
				const token = res.data.key
				// Get the user details
				axios({
					method: 'get',
					url: 'https://backend-dn7ew.ondigitalocean.app/user/',
					headers: { Authorization: `Token ${token}` },
				}).then(({ data: userData }) => {
					userData.token = token
					context.login(userData)
					history.push('/search')
				})
			})
			.catch((err) => {
				setError({ status: true, message: err.message })
			})
	}

	return (
		<div className='form-container'>
			<div className='centered'>
				<h1 className='page-title'>LOGIN</h1>
				<img alt='Somaliland Immigration Logo' src={Logo} />
			</div>
			<div>
				<Form error={error.status} onSubmit={onSubmit}>
					<Form.Field required>
						<label>Username</label>
						<input
							placeholder='Username'
							type='text'
							value={values.username}
							name='username'
							onChange={onChange}
							required
						/>
					</Form.Field>
					<Form.Field required>
						<label>Email</label>
						<input
							placeholder='Email'
							type='email'
							value={values.email}
							name='email'
							onChange={onChange}
							required
						/>
					</Form.Field>
					<Form.Field required>
						<label>Password</label>
						<input
							placeholder='Password'
							type='password'
							value={values.password}
							name='password'
							onChange={onChange}
							required
						/>
					</Form.Field>
					{error.status && (
						<Message
							error={error.status}
							header='Something Went Wrong'
							content={error.message}
						/>
					)}
					<Button basic color='teal' fluid type='submit'>
						Login
					</Button>
				</Form>
			</div>
		</div>
	)
}
export default Login
