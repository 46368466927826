import React, { useContext } from 'react'
import axios from 'axios'
import { Menu } from 'semantic-ui-react'

import { AuthContext } from '../context/auth'

const Nav = () => {
	const { user, isAuthenticated, logout } = useContext(AuthContext)

	return (
		<div>
			<Menu className='navMobile' pointing secondary size='massive' color='teal'>
				<Menu.Item header>Travel Document Verification System</Menu.Item>
				{isAuthenticated && (
					<Menu.Menu position='right'>
						<Menu.Item header>{user.name}</Menu.Item>
						<Menu.Item
							name='logout'
							onClick={() => {
								axios({
									method: 'post',
									url: 'https://backend-dn7ew.ondigitalocean.app/logout/',
								}).then(() => {
									logout()
								})
							}}
						/>
					</Menu.Menu>
				)}
			</Menu>
		</div>
	)
}

export default Nav
