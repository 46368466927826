import { useState, useEffect } from 'react'
import { Card, Grid, Image } from 'semantic-ui-react'
import { add, isFuture, isPast, isEqual } from 'date-fns'

const Result = ({ data: { given_to, serial_no, date, image, kids } }) => {
	const [className, setClassName] = useState('')
	const [text, setText] = useState('')

	useEffect(() => {
		const twoYears = add(new Date(date), { days: 15 })
		const today = new Date()

		if (isFuture(twoYears)) {
			setText('Valid')
			setClassName('valid')
			return
		} else if (isEqual(twoYears.getDate(), today.getDate())) {
			setText('Expiring Today')
			setClassName('valid')
		} else if (isPast(twoYears)) {
			setText('Expired')
			setClassName('expired')
			return
		}
	}, [className, date])

	return (
		<>
			<h1 className='text-3xl font-bold underline'>Verification</h1>
			<Card.Group>
				<Card className='fluid'>
					<Card.Content>
						<Grid columns={2}>
							<Grid.Column>
								<Card.Header>
									<div className='name'>{given_to}</div>
								</Card.Header>
								<Card.Meta>{serial_no}</Card.Meta>
								<Card.Description>
									<div className={className}>
										<span>{text}</span>
									</div>
								</Card.Description>
							</Grid.Column>
							<Grid.Column>
								<Image src={image} size='small' />
							</Grid.Column>
						</Grid>
					</Card.Content>
					{kids.length > 0 &&
						kids.map((kid) => (
							<Card.Content key={kid.id}>
								<Card.Header>Childern</Card.Header>
								<Grid columns={2}>
									<Grid.Column>
										<Card.Description>
											<div className='name'>
												<span>{kid.name}</span>
											</div>
										</Card.Description>
									</Grid.Column>
									<Grid.Column>
										<Image src={kid.image} size='small' />
									</Grid.Column>
								</Grid>
							</Card.Content>
						))}
				</Card>
			</Card.Group>
		</>
	)
}

export default Result
