import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'semantic-ui-react'

import Login from './pages/Login'
import SearchPage from './pages/Search'
import Verification from './pages/Verification'
import Nav from './components/Nav'

import { AuthProvider } from './context/auth'
import AuthRoute from './utils/AuthRoute'
import ProtectedRoute from './utils/ProtectedRoute'

import './App.css'

function App() {
	return (
		<AuthProvider>
			<Container>
				<Nav />
				<Router>
					<AuthRoute path='/' exact component={Login} />
					<ProtectedRoute path='/search' exact component={SearchPage} />
					<Route path='/verify' exact component={Verification} />
				</Router>
			</Container>
		</AuthProvider>
	)
}

export default App
