import React from 'react'
import axios from 'axios'
import { Button, Form, Grid } from 'semantic-ui-react'

import Result from '../components/Result'
import ItemExampleDescriptions from '../components/item'

const SearchPage = (props) => {
	const [serial_no, setSerial_no] = React.useState('')
	const [document, setDocument] = React.useState(null)

	const onChange = (event) => setSerial_no(event.target.value)

	const onSubmit = () => {
		const user = JSON.parse(localStorage.getItem('user'))
		axios({
			method: 'post',
			url: 'https://backend-dn7ew.ondigitalocean.app/api/search',
			headers: { 'Content-Type': 'application/json', Authorization: `Token ${user.token}` },
			data: {
				serial_no,
			},
		}).then((res) => {
			setDocument(res.data)
			setSerial_no('')
		})
	}

	return (
		<div className='form-container'>
			<div className='centered'>
				<h1 className='page-title'>SEARCH</h1>
				<p>Search with the serial number of the document.</p>
			</div>
			<div>
				<Form onSubmit={onSubmit}>
					<Grid>
						<Grid.Row>
							<Grid.Column width={11}>
								<Form.Field required>
									<input
										placeholder='Enter Serial Number'
										type='text'
										value={serial_no}
										name='serial_no'
										onChange={onChange}
										required
									/>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width={1}>
								<Button basic color='teal' type='submit'>
									Submit
								</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			</div>
			<ItemExampleDescriptions />
			{document && (
				<div className='result'>
					<Result data={document} />
				</div>
			)}
		</div>
	)
}
export default SearchPage
