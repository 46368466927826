import { useState, useEffect } from 'react'
import axios from 'axios'

import Result from '../components/Result'

const Verification = (params) => {
	const [document, setDocument] = useState(null)
	const [loading, setLoading] = useState(true)
	const query = new URLSearchParams(params.location.search)
	const serialNo = query.get('serial_no')

	useEffect(() => {
		axios({
			method: 'post',
			url: 'https://backend-dn7ew.ondigitalocean.app/api/verify',
			headers: { 'Content-Type': 'application/json' },
			data: {
				serial_no: serialNo,
			},
		}).then((res) => {
			setDocument(res.data)
			setLoading(false)
		})
	}, [serialNo])

	return (
		<div className='ui container center aligned'>
			<div className='result'>{loading ? 'Loading ...' : <Result data={document} />}</div>
		</div>
	)
}

export default Verification
